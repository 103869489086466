import React from "react";

function Container({ outClass, innerClass, children }) {
  return (
    <div
      className={`${
        outClass ? outClass : ""
      } container-full d-flex justify-content-center`}
    >
      <div className="d-flex container-geral">
        <div className={`${innerClass ? innerClass : ""} container-fluid`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default Container;
