import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { getServerData } from "actions";
import { useSession } from "next-auth/client";
import Chart from "components/Chart";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Home() {
  const router = useRouter();
  const classes = useStyles();
  const [session, loading] = useSession();

  useEffect(() => {
    if (!loading && !session?.accessToken) {
      router.push("/login");
    } else {
      router.push("/admin");
    }
  }, [loading, session]);
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
